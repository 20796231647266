import type { ExtendedLang } from '~/types/lang'

export interface IndexPartialInfluencer {
  id: number
  title: string
  subtitle: string
  picture: string
  content_lang: ExtendedLang
}

export async function getPartialInfluencers(
  locale: Locale['code'],
  fallbackLocale: Locale['code'],
): Promise<IndexPartialInfluencer[]> {
  try {
    return await $coreFetch.$get<IndexPartialInfluencer[]>(
      `/welcome/sexy-users/`,
      {
        query: {
          lang: locale,
          fallback_lang: fallbackLocale,
        },
      },
    )
  } catch (e) {
    return []
  }
}
